import React from 'react';
import { Card, Container, Nav, Navbar } from 'react-bootstrap';
import { Player, ControlBar } from 'video-react';
import 'video-react/dist/video-react.css';
import logo from "../Assets/logo.jpeg"

const AudioStreaming = () =>  {

    const onHandleLogout = (e) => {
        e.preventDefault()
        window.location.replace('/')
    }

  return (
    <>
    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center'}}>
    <Navbar  >
    <Navbar.Brand >
      <img
        alt=""
        src={logo}
        width="50"
        height="50"
        className="d-inline-block align-top"
      />{' '}
      
    </Navbar.Brand>
    <h3 style={{ fontSize: 22}}> Anjuman e Hakimi</h3>
    <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link onClick={onHandleLogout} style={{ marginLeft: 5, fontSize: 16, fontWeight: 700}}>Logout</Nav.Link>
    </Nav>
  </Navbar.Collapse>
  </Navbar>

    </div>
      <Container>
           <Card className="audio-online">
               <iframe width="560" height="315" src="https://www.youtube.com/embed/fWfjxvSkssc?si=1l8LMQWHaYqK5oSU&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
 

    </Card>

    </Container>
    <div style={{ marginTop: 30}}>
        <p>
        Company © Anjuman e Hakimi | 2023. All rights reserved.
        </p>
    </div>
    </>
  
 
  
  );
};

export default AudioStreaming
