import logo from './logo.svg';
import './App.css';
import './Style/audio.css'
import Main from './Route/main';

function App() {
  return (
    <div className="App">
     <Main />
    </div>
  );
}

export default App;
